// @ts-nocheck
import { CardWrapper, StyledCard } from './style'
interface cardLayoutProps {
  children: React.ReactNode
  margin: string
}
export default function Card({ children, margin }: cardLayoutProps) {
  return (
    <CardWrapper margin={margin}>
      <StyledCard>{children}</StyledCard>
    </CardWrapper>
  )
}
