import TextClickable from 'components/text-clickable'
import { FooterWrapper, FooterLinks, Text } from './style'
import { openExternalUrl, URLS } from 'util/linking-urls'

export default function Footer() {
  return (
    <FooterWrapper>
      <Text footer-text>© Copyright {new Date().getFullYear()} StormX Singapore PTE LTD. All Rights Reserved.</Text>
      <FooterLinks>
        <TextClickable
          link
          underline
          buttonEnabled
          text='Terms of Use'
          onClick={() => openExternalUrl(URLS.TermsOfUse)}
        ></TextClickable>
        <div>&nbsp;|&nbsp;</div>
        <TextClickable
          link
          underline
          buttonEnabled
          text='Privacy Policy'
          onClick={() => openExternalUrl(URLS.PrivacyPolicy)}
        ></TextClickable>
        <div>&nbsp;|&nbsp;</div>
        <TextClickable
          link
          underline
          buttonEnabled
          text='Support'
          onClick={() => openExternalUrl(URLS.Support)}
        ></TextClickable>
      </FooterLinks>
    </FooterWrapper>
  )
}
