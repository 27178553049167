import { handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import { requestDetails, startPromotion } from './actions'

const offerDetails = handleActions(
  {
    [requestDetails.SUCCEEDED]: (state, { payload }) => {
      return payload
    }
  },
  {}
)

const offerActivation = handleActions(
  {
    [startPromotion.SUCCEEDED]: (state, { payload }) => {
      return payload
    }
  },
  {}
)

export default combineReducers({
  offerDetails,
  offerActivation
})
