import styled from 'styled-components'

export const StyledCard = styled.div`
  width: 570px;
  background: rgb(255, 255, 255);
  border: 1px solid rgba(232, 232, 232, 0.4);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.03), 0px 4px 8px -2px rgba(0, 0, 0, 0.15);
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin};
`
