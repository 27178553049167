import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promiseMiddleware from 'redux-promise'
import { reducer as configReducer } from './config'

const reducers = combineReducers({
  config: configReducer
})

const middlewares = [thunkMiddleware, promiseMiddleware]

if (process.env.NODE_ENV !== 'production') {
  const { logger } = require('redux-logger')
  middlewares.push(logger)
}

const store = createStore(reducers, compose(applyMiddleware(...middlewares)))

export default store
