import logo from 'images/logo@3x.png'
import { HeaderWrapper, Logo } from './style'

export default function Header() {
  return (
    <HeaderWrapper>
      <Logo src={logo} alt='stormx logo' />
    </HeaderWrapper>
  )
}
