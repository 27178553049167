import styled from 'styled-components'
import { withModifiers } from 'util/styles'

export const Text = styled.label`
  color: rgb(29, 29, 29);
  font-size: 11px;
  font-family: Adelle-Regular;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0px;
  line-height: 18px;

  &:hover {
    opacity: 0.5;
  }

  &:focus {
    box-shadow: none !important;
  }

  ${withModifiers('underline')`
    text-decoration-line: underline;
  `};

  ${withModifiers('link-in-card')`
    font-size: 14px;
  `}
`
