import { request } from 'util/network'
import { getStorage } from 'util/storage'
import { highestKey } from 'util/config'

export async function requestDetails(id: string) {
  const { offer } = await request(`/v2/shops/${id}`, {
    method: 'GET'
  })

  const highest = await getStorage(highestKey)
  const updatedOffer = {
    ...offer,
    highest
  }

  return updatedOffer
}

export async function startPromotion(id: string) {
  const options = { method: 'POST' }
  return request(`/offers/${id}`, options)
}
