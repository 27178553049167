import Card from 'components/card'
import ShopActivationLayout from 'components/shop-activation-layout'
import SpinnerImage from 'images/spinner@3x.png'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { isEmpty } from 'lodash'
import { Text, Spinner, CardBody, ExclusionTab } from './style'

export default function ActivateDealContent({ offer }) {
  return (
    <ShopActivationLayout>
      <Card margin='80px 0px 0px'>
        <CardBody>
          <Spinner src={SpinnerImage} alt='activate deal spinner with content' />
          <Text activating-deal-text>Activating your deal...</Text>

          {!isEmpty(offer) ? (
            <Text activating-shop-title>{offer.title}</Text>
          ) : (
            <Skeleton width={100} height={26} style={{ marginTop: 35, marginBottom: 32 }} />
          )}

          {!isEmpty(offer) ? (
            <Text crypto-cash-percentage>Up to {offer.highest} Crypto Cashback!</Text>
          ) : (
            <Skeleton width={200} height={18} />
          )}
          <Text crypto-cast-description-in-card>
            You're all set. Make a purchase and your crypto earnings will be credited to your StormX account.
          </Text>
        </CardBody>
      </Card>
      <ExclusionTab>
        <Text exclusions-title>Exclusions apply:</Text>

        {!isEmpty(offer) ? (
          <Text exclusions>
            <div
              dangerouslySetInnerHTML={{
                __html: offer.tc
              }}
            />
          </Text>
        ) : (
          <Text exclusions>
            <Skeleton count={6} />
          </Text>
        )}
      </ExclusionTab>
    </ShopActivationLayout>
  )
}
