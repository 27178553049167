export const getAllUrlParams = (url) => {
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1)

  var obj = {
    accountid: '',
    highest: '',
    offerid: '',
    udid: '',
    token: '',
    version: '',
    affid: ''
  }

  if (queryString) {
    queryString = queryString.split('#')[0]

    var arr = queryString.split('&')
    for (var i = 0; i < arr.length; i++) {
      var a = arr[i].split('=')

      var paramName = a[0]
      var paramValue = typeof a[1] === 'undefined' ? '' : a[1]

      paramName = paramName.toLowerCase()
      if (!obj[paramName]) {
        obj[paramName] = paramValue
      } else if (obj[paramName] && typeof obj[paramName] === 'string') {
        obj[paramName] = [obj[paramName]]
        obj[paramName].push(paramValue)
      } else {
        obj[paramName].push(paramValue)
      }
    }
  }

  // clear attributes from url
  //   window.history.replaceState(null, null, window.location.pathname);

  return obj
}
