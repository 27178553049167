import Card from 'components/card'
import ShopActivationLayout from 'components/shop-activation-layout'
import AdBlockImage from 'images/adblock@3x.png'
import { AdblockImage, CardBody, ActivateAgainButton, Text } from './style'

export default function AdblockDetected() {
  return (
    <ShopActivationLayout>
      <Card margin='110px 0px'>
        <CardBody>
          <AdblockImage src={AdBlockImage} alt='adblock detected' />
          <Text adblock-title>Ad Blocker Trouble</Text>
          <Text adblock-content>
            Please whitelist https://stormx.io on your adblocker or disable it to ensure that you will be properly
            rewarded!
          </Text>
          <ActivateAgainButton>
            <Text activate-again-button-text>Activate Again</Text>
          </ActivateAgainButton>
        </CardBody>
      </Card>
    </ShopActivationLayout>
  )
}
