export const URLS = {
  Help: 'https://help.stormx.io/',
  Support: 'https://help.stormx.io/hc/en-us',
  TermsOfUse: 'https://stormx.io/terms-of-use',
  PrivacyPolicy: 'https://stormx.io/privacy-policy'
}

export const openExternalUrl = (url: string) => {
  window.open(url)
}
