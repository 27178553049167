import Footer from 'components/footer'
import Header from 'components/header'
import { StyledDiv } from './style'

interface layoutProps {
  children: React.ReactNode
}
export default function ShopActivationLayout({ children }: layoutProps) {
  return (
    <StyledDiv>
      <Header />
      {children}
      <Footer />
    </StyledDiv>
  )
}
