import styled from 'styled-components'
import { withModifiers } from 'util/styles'

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;
  ${withModifiers('adblock-title')`
  width: 530px;
  height: 22px;
  color: rgb(29, 29, 29);
  font-size: 17px;
  font-family: Montserrat-Bold;
  font-weight: bold;
  text-align: center;
  line-height: 22px;
  margin-top: 30px;
  `}
  ${withModifiers('adblock-content')`
  width: 425px;
  height: 40px;
  color: rgb(29, 29, 29);
  font-size: 14px;
  font-family: Adelle-Regular;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.2px;
  line-height: 20px;
  margin-top: 17px;
  `}
  ${withModifiers('activate-again-button-text')`
  width: 184px;
  height: 18px;
  color: rgb(254, 255, 255);
  font-size: 14px;
  font-family: Montserrat-Bold;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  &:hover {
    opacity: 0.5;
    cursor:pointer;
  }
  `}
`
export const AdblockImage = styled.img`
  width: 210px;
  height: 140px;
`
export const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0px;
`
export const ActivateAgainButton = styled.button`
  width: 220px;
  height: 44px;
  background: rgb(253, 46, 83);
  border-radius: 22px;
  border-color: transparent;
  margin-top: 30px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`
