import { osName } from 'react-device-detect'
import { removeSpecialChars } from '../formatting'

const defaultHeaders = {
  'Content-Type': 'application/json',
  'User-Agent': removeSpecialChars(navigator.userAgent),
  systemName: osName || 'Unknown'
}

export const setHeaders = async () => {
  return { ...defaultHeaders }
}

export async function checkStatus(response) {
  const data = await response.json()
  if (response.status !== 200) {
    const error = {
      ...data,
      correlationId: response.headers.get('X-Correlation-ID') || ''
    }

    throw error
  }

  return data
}
