import Card from 'components/card'
import ShopActivationLayout from 'components/shop-activation-layout'
import ActivateFailedImage from 'images/failed@3x.png'
import TextClickable from 'components/text-clickable'
import { openExternalUrl, URLS } from 'util/linking-urls'
import { ErrorComponentProps } from 'util/interfaces'
import { Text, CardBody, ActivateFailed, ActivateAgainButton } from './style'

const ActivateDealFailed = ({ activateOfferAgain }: ErrorComponentProps) => {
  return (
    <ShopActivationLayout>
      <Card margin='90px 0px'>
        <CardBody>
          <ActivateFailed src={ActivateFailedImage} alt='activate deal failed' />
          <Text deal-failed-title>Snap! Failed to start shopping!</Text>
          <Text deal-failed-content>
            Please reactivate the promotion and try again. If the issue is not resolved, please try initiating crypto
            back again from StormX.
          </Text>
          <br />
          <Text>
            If the issue persists, please contact us at{' '}
            <TextClickable
              underline
              buttonEnabled
              link-in-card
              text='help.stormx.io'
              onClick={() => openExternalUrl(URLS.Help)}
            ></TextClickable>
            .
          </Text>
          <ActivateAgainButton onClick={async () => activateOfferAgain()}>
            <Text activate-again-button-text>Activate Again</Text>
          </ActivateAgainButton>
        </CardBody>
      </Card>
    </ShopActivationLayout>
  )
}

export default ActivateDealFailed
