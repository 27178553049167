import styled from 'styled-components'
import { withModifiers } from 'util/styles'

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('activating-deal-text')`
    width: 400px;
    height: 20px;
    color: rgb(253, 46, 83);
    font-size: 14px;
    font-family: Adelle-Regular;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin-top:12px;
  `};

  ${withModifiers('activating-shop-title')`
    width: 100%;
    height: 40px;
    color: rgb(29, 29, 29);
    font-size: 30px;
    font-family: Montserrat-ExtraBold;
    font-weight: 800;
    text-align: center;
    line-height: 40px;
    margin-top:30px;
  `};

  ${withModifiers('crypto-cash-percentage')`
    width: 400px;
    height: 22px;
    color: rgb(250, 46, 83);
    font-size: 17px;
    font-family: Montserrat-Bold;
    font-weight: bold;
    text-align: center;
    line-height: 22px;
    margin-top:20px;
  `};

  ${withModifiers('crypto-cast-description-in-card')`
    width: 400px;
    height: 40px;
    color: rgb(29, 29, 29);
    font-size: 14px;
    font-family: Adelle-Regular;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin-top:20px;
  `};

  ${withModifiers('exclusions-title')`
    width: 530px;
    height: 22px;
    color: rgb(29, 29, 29);
    font-size: 17px;
    font-family: Montserrat-Bold;
    font-weight: bold;
    text-align: center;
    line-height: 22px;
    margin-top:50px;
  `};

  ${withModifiers('exclusions')`
    width: 530px;
    color: rgb(29, 29, 29);
    font-size: 11px;
    font-family: Adelle-Regular;
    letter-spacing: 0px;
    line-height: 16px;
    margin: 20px 0px 80px 0px;
  `}
`

export const Spinner = styled.img`
  width: 58px;
  height: 58px;
  animation: rotation 2s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

export const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 49.5px 0px;
`

export const ExclusionTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
