import { getStorage } from 'util/storage'
import { browserName } from 'react-device-detect'
import { checkStatus, setHeaders } from './helper'
import { removeSpecialChars } from 'util/formatting'
import { baseUrl, tokenKey, udidKey, versionKey, affIdKey } from 'util/config'

export async function request(url, options) {
  const udid = await getStorage(udidKey)
  const token = await getStorage(tokenKey)
  const version = await getStorage(versionKey)
  const affId = await getStorage(affIdKey)

  const extraHeaders = await setHeaders()
  const actualUrl = `${baseUrl}${url}`

  const actualOptions = {
    ...options,
    headers: {
      udid,
      token,
      'aff-id': affId,
      Version: version,
      // this is for backwards compatibility. the old version of extension uses appcCodeName
      devicename: removeSpecialChars(version === '1.11.5' ? navigator.appCodeName : browserName),
      ...extraHeaders
    }
  }

  return fetch(actualUrl, actualOptions).then(checkStatus)
}
