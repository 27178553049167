import AdblockDetected from 'components/adblock-detected'
import Config from 'state/config/model'
import ActivateDealContent from 'components/activate-deal-content'
import ActivateDealFailed from 'components/activate-deal-failed'
import { isEmpty, get } from 'lodash'
import { connect } from 'react-redux'
import { HomeComponentProps } from 'util/interfaces'
import { useEffect, useState, useCallback } from 'react'
import { getAllUrlParams } from 'util/deal-content'
import { actions as configActions } from 'state/config'
import { getStorage, setStorage, removeStorage } from 'util/storage'
import { amplitudeSendData, amplitudeTrackUser, amplitudeInitialize } from 'util/amplitude'
import { udidKey, tokenKey, offeridKey, highestKey, accountIdKey, versionKey, affIdKey } from 'util/config'

const Home = ({ offerDetails, startPromotion, requestDetails, offerActivation }: HomeComponentProps) => {
  const [adBlockDetected, setAdBlockDetected] = useState(false)
  const [displayError, setDisplayError] = useState(false)

  const requestOfferAndActivate = useCallback(
    async (offerId: string) => {
      const offer = await requestDetails(offerId)

      if (get(offer, 'id', undefined)) {
        startPromotion(offerId)
      } else {
        setDisplayError(true)
      }
    },
    [requestDetails, startPromotion]
  )

  const activateOfferAgain = async () => {
    const offerId = await getStorage(offeridKey)

    if (offerId) {
      setDisplayError(false)
      requestOfferAndActivate(offerId)
    }
  }

  useEffect(() => {
    amplitudeInitialize()

    const url = ['https://www3.doubleclick.net']

    url.forEach((item) => {
      fetch(item, {
        method: 'HEAD',
        mode: 'no-cors',
        cache: 'no-store'
      })
        .then(async (res) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('adblocker response', res)
          }

          const data = getAllUrlParams(window.location.href)

          if (process.env.NODE_ENV !== 'production') {
            console.log('url data: ', data)
          }

          if (data.accountid && data.offerid) {
            amplitudeTrackUser(data.accountid)

            await setStorage(accountIdKey, data.accountid)
            await setStorage(offeridKey, data.offerid)
            await setStorage(udidKey, data.udid)
            await setStorage(tokenKey, data.token)
            await setStorage(highestKey, data.highest)
            await setStorage(versionKey, !isEmpty(data.version) ? data.version : '1.11.5')
            await setStorage(affIdKey, !isEmpty(data.affid) ? data.affid : '5')

            requestOfferAndActivate(data.offerid)
          }
        })
        .catch((err) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('adblocker error:', err)
          }

          setAdBlockDetected(true)
        })
    })
  }, [requestOfferAndActivate])

  useEffect(() => {
    if (adBlockDetected) {
      amplitudeSendData('shop-activation-blocked', {
        adblocker: true
      })
    }
  }, [adBlockDetected, offerDetails])

  useEffect(() => {
    if (!isEmpty(offerActivation)) {
      const url = offerActivation.payload.redirectUrl

      const removeDataFromStorage = async () => {
        await removeStorage(accountIdKey)
        await removeStorage(highestKey)
        await removeStorage(offeridKey)
        await removeStorage(udidKey)
        await removeStorage(tokenKey)
        await removeStorage(versionKey)
        await removeStorage(affIdKey)
      }

      removeDataFromStorage()

      window.location.replace(url)
    }
  }, [offerActivation])

  return (
    <>
      {adBlockDetected ? (
        <AdblockDetected />
      ) : displayError ? (
        <ActivateDealFailed activateOfferAgain={activateOfferAgain} />
      ) : (
        <ActivateDealContent offer={offerDetails} />
      )}
    </>
  )
}

const enhancer = connect(
  (state: { config: Config }) => ({
    offerDetails: state.config.offerDetails,
    offerActivation: state.config.offerActivation
  }),
  {
    requestDetails: configActions.requestDetails,
    startPromotion: configActions.startPromotion
  }
)

export default enhancer(Home)
