import { Text } from './style'

interface TextClickableProps {
  buttonEnabled: boolean
  underline?: boolean
  onClick: () => void
  text: string
}

const TextClickable = ({ buttonEnabled, onClick, text, ...props }: TextClickableProps) => {
  return (
    <Text {...props} onClick={buttonEnabled ? onClick : undefined}>
      {text}
    </Text>
  )
}

export default TextClickable
