import styled from 'styled-components'
import { withModifiers } from 'util/styles'

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('footer-text')`
  width: 484px;
  color: rgb(29, 29, 29);
  font-size: 11px;
  font-family: Adelle-Regular;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0px;
  line-height: 18px;
`}
`

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const FooterLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`
